.react-excel .active-sheet {
  background-color: #a1d6e2;
  color: rgb(65, 65, 66);
}

.react-excel div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0rem;
}

.react-excel div button {
  border: 0px solid black;
  border-radius: 0px;
  padding: 0 0;
  color: rgb(86, 86, 231);
  cursor: pointer;
  font-size: small;
  display: none;
}

.react-excel table {
  margin: 0rem 0;
  border-collapse: collapse;
}

.react-excel th, .react-excel td {
  border: 1px solid #ddd;
  padding: 8px;
}

.react-excel table tr:nth-child(even) {
  background-color: white;
}
