body {
  background-color: rgba(250, 250, 250, 0.911);
}

.container {
  width: 100%;
  height: 500;

  top: 0%;
  left: 0%;
}

.sigContainer {
  width: 100%;
  height: 100%;

  margin: 100;
  background-color: rgba(204, 204, 204, 0.836);
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 0px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
